<template>
  <v-expansion-panels
    v-model="tab.selected"
    accordion multiple
  >
    <v-expansion-panel v-for="(data, i) in items" :key="i">
      <app-can-view :code="data.permission">
        <v-expansion-panel-header color="transparent">
          <div>
            <span v-text="$trans(data.name)"></span>
            <v-chip
              v-if="data.chipVisible" small
              class="ml-3"
              v-text="chipText(data)"
            ></v-chip>
          </div>
        </v-expansion-panel-header>
      </app-can-view>
      <v-expansion-panel-content
        :key="data.id"
        :eager="data.eager"
        color="transparent"
      >
        <app-can-view :code="data.permission">
          <component :is="data.component" :item.sync="data"></component>
        </app-can-view>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'ReportViewerMetaInfoSummary',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: () => ({
    tab: {
      selectedIDs: [ 'well', 'map' ],
      selected: [ 0, 3 ],
      items: [
        {
          id: 'relatedDocuments',
          key: 'relatedDocuments',
          name: 'Related Documents',
          eager: false,
          chip: false,
          disabled: false,
          open: false,
          permission: 'report_related_documents',
          component: () => import('./Summary/RelatedDocumentsDataIterator'),
        },
        {
          id: 'generalSummaryInfo',
          key: 'generalSummaryInfo',
          name: 'General Information',
          eager: true,
          chip: false,
          disabled: false,
          open: true,
          always: true,
          permission: 'report_well_information',
          component: () => import('./Summary/GeneralSummaryInfoTable'),
        },
        {
          id: 'well',
          key: 'relatedWells',
          name: 'Well Information',
          eager: true,
          chip: false,
          disabled: false,
          open: false,
          permission: 'report_well_information',
          component: () => import('./Summary/WellInformationTable'),
        },
        {
          id: 'publication',
          key: 'publication',
          name: 'Publication Information',
          eager: true,
          chip: false,
          disabled: false,
          open: false,
          permission: 'report_well_information',
          component: () => import('./Summary/PublicationInformationTable'),
        },
        {
          id: 'wordcloud',
          key: 'wordcloud',
          name: 'Word cloud',
          eager: false,
          hasItem: true,
          chip: false,
          chipVisible: false,
          disabled: false,
          open: false,
          permission: 'report_wordcloud',
          component: () => import('./Summary/WordCloudMap'),
        },
        {
          id: 'map',
          key: 'relatedWells',
          name: 'Map',
          eager: true,
          chip: false,
          chipVisible: false,
          disabled: false,
          always: true,
          open: false,
          permission: 'report_map',
          component: () => import('./Summary/WorldMapArea'),
        },
        {
          id: 'sandbox',
          key: 'sandbox',
          name: 'GenAI Sandbox',
          eager: true,
          chip: false,
          chipVisible: false,
          disabled: false,
          always: true,
          open: false,
          permission: 'report_well_information',
          component: () => import('./Summary/Sandbox'),
        },
        {
          id: 'docChat',
          key: 'docChat',
          name: 'Document Chatbot',
          eager: true,
          chip: false,
          chipVisible: false,
          disabled: false,
          always: true,
          open: false,
          permission: 'report_well_information',
          component: () => import('./Summary/DocumentChat'),
        },
      ],
    },
  }),

  computed: {
    items () {
      return this.tab.items.map(tab => ({
        ...tab,
        open: tab.open,
        hasItem: tab.hasItem || !isEmpty(this.item[tab.key]),
        chip: this.item?.[tab.key]?.chip,
        chipVisible: tab.chipVisible ?? this.item?.[tab.key]?.chipVisible,
        data: {
          ...this.item[tab.key],
          ...this.item?.[tab.key]?.attributes,
        },
      })).filter(tab => (tab.always ?? tab.hasItem));
    },
  },

  watch: {
    'tab.selected': function (selected) {
      const selectedItems = this.items
        .filter((item, i) => selected.includes(i))
        .map(i => i.id);
      this.tab.items = this.tab.items
        .map(item => ({ ...item, open: selectedItems.includes(item.id) }));
    },
  },
  
  methods: {
    chipText(data) {
      return data.id === "relatedDocuments" ? data.chip - 1 : data.chip;
    }
  }
};
</script>
