import DataResource from "@core/resources/DataResource";
import {
    DOCUMENT_SUMMARY, 
    FAVORITES_SUMMARY,
    SANDBOX_URL,
    DOCUMENT_CHAT,
 } from "@/modules/Search/api/chatbot";

export default class Chatbot extends DataResource{
    constructor (options = {}) {
      super(options);
      this.setMeta({
        hasTables: false
      })
    }

    async getFavoritesSummary (favorites) {
        this.startLoading()
        let body = {
          favorites: [],
        };
        favorites.map(i => {
            body.favorites.push(i.id)
        });
        const { data } = await this.axios.post(FAVORITES_SUMMARY, body) 
        const tables = this.extractTables(data.data.summary)
        this.meta.tables = tables
        this.meta.hasTables = tables.length > 0
        this.setData(data)
        this.stopLoading()
    }
    async getDocumentsSummary (fileId) {
        this.startLoading()
        const body = {
            file_id: fileId
        }
        const { data } = await this.axios.post(DOCUMENT_SUMMARY, body) 
        const tables = this.extractTables(data.data.summary)
        this.meta.tables = tables
        this.meta.hasTables = tables.length > 0
        this.setData(data)
        this.stopLoading()
    }
    async getSandboxResponse (query) {
        this.startLoading()
        const body = {
            context: query
        }
        const { data } = await this.axios.post(SANDBOX_URL, body)
        console.log(data)
        const tables = this.extractTables(data.data.summary)
        this.meta.tables = tables
        this.meta.hasTables = tables.length > 0
        this.setData(data)
        this.stopLoading()
    }
    async getDocumentResponse (query, fileId) {
        this.startLoading()
        console.log(query)
        console.log(fileId)
        const body = {
            user_prompt: query,
            file_id: fileId
        }
        const { data } = await this.axios.post(DOCUMENT_CHAT, body)
        console.log(data)
        const tables = this.extractTables(data.data.summary)
        this.meta.tables = tables
        this.meta.hasTables = tables.length > 0
        this.setData(data)
        this.stopLoading()
    }

    copyToClipboard(text) {
        const formattedText = text.replace(/\*\*/g, '')
        navigator.clipboard.writeText(formattedText)
          .then(() => {
            this.copySuccess = true;
            setTimeout(() => (this.copySuccess = false), 2000);
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
    }

    extractTables(mdText) {
        const tableRegex = /((?:^\|.*?\|\s*\n)+^\|[-\s:|]+\|\s*\n(?:^\|.*?\|\s*\n)+)/gm;
        let tables = [];
        let match;

        // Find all tables and push them into the tables array
        while ((match = tableRegex.exec(mdText)) !== null) {
          tables.push(match[0].trim());
        }

        return tables;
    }

    exportCsvFile(fileName, csvData) {
      const csvContent = csvData.join("\n");
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".csv";
      link.click();
    }

    convertToCsv(markdownTable) {
      const rows = markdownTable.split("\n");
  
      let csvRows = [];
  
      let headerRow = rows.shift();
      let csvHeaderRow = [];
      for (const cell of headerRow.replace(/^|$/g, '').split("|")) {
          csvHeaderRow.push(cell);
      }
  
      csvRows.push(csvHeaderRow.join(","));
  
      while (rows.length > 0) {
          const row = rows.shift().replace(/^|$/g, '');
          if (row !== "") { // Skip empty lines
              let csvRow = [];
              for (const cell of row.split("|")) {
                  csvRow.push(cell);
              }
              csvRows.push(csvRow.join(","));
          }
      }
  
      return csvRows;
    }

}